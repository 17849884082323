import {ChevronsDown, Code, createIcons, Rocket, Settings, Users2} from 'lucide';
import axios from "axios";
import Toastify from 'toastify-js';
import "toastify-js/src/toastify.css";

createIcons({
  icons: {
    ChevronsDown,
    Code,
    Settings,
    Users2,
    Rocket,
  },
});

const contactForm = document.querySelector('#contact-form');
contactForm.addEventListener('submit', async function (event) {
  event.preventDefault();

  const formWebhookUrl = 'https://eoc2m1l0syz6yxo.m.pipedream.net';

  await axios.post(formWebhookUrl, this, {
    headers: {
      'Content-Type': 'application/json'
    }
  }).then(() => {
    Toastify({
      text: "Form submitted successfully!",
      duration: 3000,
      close: true,
      gravity: "bottom",
      position: 'right',
      style: "linear-gradient(to right, #00b09b, #96c93d)",
    }).showToast();
  }).catch(() => {
    Toastify({
      text: "Form submission failed!",
      duration: 3000,
      close: true,
      gravity: "bottom",
      position: 'right',
      backgroundColor: "linear-gradient(to right, #ff5f6d, #ffc371)",
    }).showToast();
  });
});

let callback = (entries) => {
  entries.forEach((entry) => {
    entry.target.style.opacity = entry.intersectionRatio
  });
};

let options = {
  root: null,
  threshold: [0.4, 0.6, 0.8, 1]
}
let observer = new IntersectionObserver(callback, options);

for (const target of document.querySelectorAll('#projects .project-item')) {
  observer.observe(target);
}

(function () {
  window.requestAnimationFrame = window.requestAnimationFrame || function (callback) {
    window.setTimeout(callback, 1000 / 60);
  };
})();

const section = document.querySelector('.canvas-section');
const background = document.getElementById("bgCanvas");
const bgCtx = background.getContext("2d");
const width = section.offsetWidth;
const height = section.offsetHeight;

background.width = section.offsetWidth;
background.height = section.offsetHeight;

function Terrain(options) {
  options = options || {};
  this.terrain = document.createElement("canvas");
  this.terCtx = this.terrain.getContext("2d");
  this.scrollDelay = options.scrollDelay || 90;
  this.lastScroll = new Date().getTime();

  this.terrain.width = width;
  this.terrain.height = height;
  this.fillStyle = options.fillStyle || "#191D4C";
  this.mHeight = options.mHeight || height;

  // Generate terrain points
  this.points = [];
  let displacement = options.displacement || 140,
    power = Math.pow(2, Math.ceil(Math.log(width) / (Math.log(2))));

  // Set the start height and end height for the terrain
  this.points[0] = this.mHeight;
  this.points[power] = this.points[0];

  // Create the rest of the points
  for (let i = 1; i < power; i *= 2) {
    for (let j = (power / i) / 2; j < power; j += power / i) {
      this.points[j] = ((this.points[j - (power / i) / 2] + this.points[j + (power / i) / 2]) / 2) + Math.floor(Math.random() * -displacement + displacement);
    }
    displacement *= 0.6;
  }

  section.appendChild(this.terrain);
}

Terrain.prototype.update = function () {
  this.terCtx.clearRect(0, 0, width, height);
  this.terCtx.fillStyle = this.fillStyle;

  if (new Date().getTime() > this.lastScroll + this.scrollDelay) {
    this.lastScroll = new Date().getTime();
    this.points.push(this.points.shift());
  }

  this.terCtx.beginPath();
  for (let i = 0; i <= width; i++) {
    if (i === 0) {
      this.terCtx.moveTo(0, this.points[0]);
    } else if (this.points[i] !== undefined) {
      this.terCtx.lineTo(i, this.points[i]);
    }
  }

  this.terCtx.lineTo(width, this.terrain.height);
  this.terCtx.lineTo(0, this.terrain.height);
  this.terCtx.lineTo(0, this.points[0]);
  this.terCtx.fill();
}

bgCtx.fillStyle = "rgba(18, 54, 71, 1)";
bgCtx.fillRect(0, 0, width, height);

const entities = [];

entities.push(new Terrain({displacement: 120, scrollDelay: 50, fillStyle: "rgb(17,20,40)", mHeight: height / 4 - 30}));
entities.push(new Terrain({displacement: 100, scrollDelay: 20, fillStyle: "rgb(0,0,0)", mHeight: height / 4}));

function animate() {

  let entLen = entities.length;
  while (entLen--) {
    entities[entLen].update();
  }
  requestAnimationFrame(animate);
}
animate();
